import { createContext, useState, useMemo, useContext, useCallback } from 'react';
import { PROCESS_DETAILS_SECTIONS_CONFIGS } from 'constants/processDetails/configs';
import UserContext from './userContext';
import { TimelineContextProvider } from './timelineContext';
import { GeneralInformationContextProvider } from './processDetails/generalInformationContext';
import { ClientInformationContextProvider } from './processDetails/clientInformationContext';
import { FinantialInformationContextProvider } from './processDetails/finantialInformationContext';
import { TechnicalSummaryContextProvider } from './processDetails/technicalSummaryContext';
import { EquipmentsDetailsContextProvider } from './processDetails/equipmentsDetailsContext';
import { TIRContextProvider } from './processDetails/tirContext';
import { getBoolean } from 'services/util/auxiliaryUtils';
import { PROCESS_DETAILS_INPUT_NAMES } from 'constants/processDetails';
import { SITE_SURVEY_CONFIGS } from 'constants/processDetails/siteSurvey/configs';
import { ReasonsContextProvider } from './processDetails/reasonsContext';
import { array, bool, func, number } from 'prop-types';
import useDropdownContext from 'hooks/contexts/useDropdownContext';

const ProcessDetailsContext = createContext({
    processDetails: array,
    processDetailsTab: number
});
ProcessDetailsContext.displayName = 'efz-ProcessDetailsContext'

const DropdownContext = createContext({
    salesOperationManagerFilters: array,
    activeSalesOperationManagerFilters: array,
    notStandardReasonsOptions: array,
    vynReasonsOptions: array,
    fetchSOMOptions: func,
    fetchOptions: func,
    isLoadingSOMFilter: bool,
    isLoadingNotStandardReasons: bool,
})
DropdownContext.displayName = 'efz-DropdownContext'

const {
    SIMULATION_OUTPUTS,
    HAS_BATTERY,
    IS_REFORMULATED
} = PROCESS_DETAILS_INPUT_NAMES;

const ProcessDetailsContextProvider = ({ children }) => {

    const {
        userTypeID,
        userCompanyID
    } = useContext(UserContext);

    const [processDetails, setProcessDetails] = useState([]);
    const [processDetailsTab, setProcessDetailsTab] = useState(0);
    const [evt, setEvt] = useState({
        type: null,
        values: {}
    })

    const configSections = useMemo(() => (
        PROCESS_DETAILS_SECTIONS_CONFIGS?.[userCompanyID]?.[userTypeID]?.[processDetails?.[processDetailsTab]?.product_type_id]
    ), [processDetails, userCompanyID, userTypeID, processDetailsTab]);

    const configSiteSurvey = useMemo(() => SITE_SURVEY_CONFIGS?.[userCompanyID]?.[userTypeID]?.[processDetails?.[processDetailsTab]?.product_type_id],
        [processDetails, userCompanyID, userTypeID, processDetailsTab])

    const oldID = useMemo(() => processDetails?.find(process => !process?.[IS_REFORMULATED])?.contract_id,[processDetails])

    const reformulateIDs = useMemo(() => processDetails?.filter(process => process?.[IS_REFORMULATED]).map(process => process?.contract_id), [processDetails])

    const handleChangeProcessDetailTab = useCallback((event, tab) => {
        setProcessDetailsTab(tab)
    }, [])

    const {
        salesOperationManagerFilters,
        activeSalesOperationManagerFilters,
        notStandardReasonsOptions,
        vynReasonsOptions,
        fetchSOMOptions,
        fetchOptions,
        isLoadingSOMFilter,
        isLoadingNotStandardReasons
    } = useDropdownContext()

    const dropdownContextValues = {
        salesOperationManagerFilters,
        activeSalesOperationManagerFilters,
        notStandardReasonsOptions,
        vynReasonsOptions,
        fetchSOMOptions,
        fetchOptions,
        isLoadingSOMFilter,
        isLoadingNotStandardReasons,
    }

    return (
        <ProcessDetailsContext.Provider
            value={{
                configSections,
                processDetailsTab,
                handleChangeProcessDetailTab,
                processDetails,
                setProcessDetails,
                userTypeID,
                productTag: processDetails?.[0]?.description,
                fieldDataSS: processDetails?.[0]?.field_data_ss,
                evt,
                setEvt,
                processID: processDetails?.[0]?.contract_id,
                configSiteSurvey,
                hasBattery: getBoolean(processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS]?.[0]?.[HAS_BATTERY]),
                //Reformulation
                hasReformulation: processDetails?.length > 1 && processDetails?.[0]?.[IS_REFORMULATED],
                reformulateIDs,
                oldID,
                paymentTag: processDetails?.[0]?.payment_method
            }}
        >
            <TimelineContextProvider>
                <TIRContextProvider>
                    <ReasonsContextProvider>
                        <GeneralInformationContextProvider>
                            <ClientInformationContextProvider>
                                <FinantialInformationContextProvider>
                                    <TechnicalSummaryContextProvider>
                                        <EquipmentsDetailsContextProvider>
                                            <DropdownContext.Provider value={dropdownContextValues}>
                                                {children}
                                            </DropdownContext.Provider>
                                        </EquipmentsDetailsContextProvider>
                                    </TechnicalSummaryContextProvider>
                                </FinantialInformationContextProvider>
                            </ClientInformationContextProvider>
                        </GeneralInformationContextProvider>
                    </ReasonsContextProvider>
                </TIRContextProvider>
            </TimelineContextProvider>
        </ProcessDetailsContext.Provider>
    )
}

export { ProcessDetailsContext, ProcessDetailsContextProvider, DropdownContext }