import { PROCESS_STATE_IDS, PRODUCTS_WITH_TECHNICAL_SUMMARY, TIR_ERRORS } from "constants/processDetails";
import { PRODUCT_TAG, PRODUCT_IDS } from 'constants/product';
import clone from 'fast-copy';
import { PROCESS_DETAILS_INPUT_NAMES, REFORMULATED_ERROR_TAGS } from 'constants/processDetails';
import { PAYMENT_METHOD_TAGS } from 'constants/businessModels';
import { COMPANY_IDS } from "constants/settings";
import { INPUT_NAMES } from "constants/processesFilters";
import { checkEquality, getGeneralParseNumber, isDefined, isFieldDefined } from "services/util/auxiliaryUtils";
import IntlMessages from "components/util/IntlMessages";
import { SITE_SURVEY_NAMES } from "constants/processDetails/siteSurvey/configs";
import { StructuresNamesArray } from "constants/processDetails/indexV2";

export const hasTechnicalSummary = (product) => PRODUCTS_WITH_TECHNICAL_SUMMARY.includes(product);
export const isSPV = (product) => product === PRODUCT_TAG.SPV;
export const isME = (product) => product === PRODUCT_TAG.ME;

export const parseEquipmentDetailsValues = (values, getSeparatorValue) => {

    const submitedValues = clone(values);

    const posParseValues = submitedValues?.[PROCESS_DETAILS_INPUT_NAMES.SIMULATION_OUTPUTS].map((group) => {
        const area_paines_grupo_paines = getGeneralParseNumber(group?.[PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES], getSeparatorValue());
        const inclinacao_graus_grupo_paineis = getGeneralParseNumber(group?.[PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES], getSeparatorValue());
        const orientacao_graus_grupo_paineis = getGeneralParseNumber(group?.[PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS], getSeparatorValue());
        const potencia_ac_kva = getGeneralParseNumber(group?.[PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA], getSeparatorValue());
        const potencia_dc_kw = getGeneralParseNumber(group?.[PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW], getSeparatorValue());
        return {
            ...group,
            [PROCESS_DETAILS_INPUT_NAMES.AREA_PAINES_GRUPO_PAINES]: parseFloat(area_paines_grupo_paines),
            // [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_PICO_KWP_GRUPO_PAINES]: parseFloat(potencia_pico_kwp_grupo_paineis),
            [PROCESS_DETAILS_INPUT_NAMES.INCLINACAO_GRAUS_GRUPO_PAINES]: parseFloat(inclinacao_graus_grupo_paineis),
            [PROCESS_DETAILS_INPUT_NAMES.ORIENTACAO_GRAUS_GRUPO_PAINEIS]: parseFloat(orientacao_graus_grupo_paineis),
            [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_AC_KVA]: parseFloat(potencia_ac_kva),
            [PROCESS_DETAILS_INPUT_NAMES.POTENCIA_DC_KW]: parseFloat(potencia_dc_kw),
        }
    })

    return {
        pos: posParseValues
    };
}

export const showInput = (userID, productID, paymentTAG, companyID) => {
    if (productID === PRODUCT_IDS.SPV && (
        isV2Service(paymentTAG, companyID) ||
        (paymentTAG) === PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED)) {
        return false;
    } else {
        return true;
    }
}

export const isV2Service = (paymentMethod, companyID) => {
    if ([COMPANY_IDS.EDP_PT, COMPANY_IDS.EFZ].includes(companyID)) {
        return paymentMethod === PAYMENT_METHOD_TAGS.SERVICE_SECOND_VERSION;
    } else {
        return paymentMethod === PAYMENT_METHOD_TAGS.SERVICE;
    }
}; //FIXME: VERIFICAR IDS E TAGS COM BE!!!

export const isPaPService = (paymentMethod) => (paymentMethod) === PAYMENT_METHOD_TAGS.PAY_AS_PRODUCED;

export const insertAriaTabs = (index) => {
    return {
        id: `processDetails-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const isTabSelected = (tabIndex, selectIndex) => (tabIndex === selectIndex);

/**
 * 
 * @param {array} detailsArr 
 * @returns array
 */
export const arrangeProcessDetails = (detailsArr) => {
    let arrangedDetails = [];

    // for (let i = 0; i < detailsArr.length; i++) {
    //     if (!!detailsArr[i][PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED]) {
    //         arrangedDetails.unshift(detailsArr[i]);
    //     } else {
    //         arrangedDetails.push(detailsArr[i]);
    //     }
    // }
    for (let i = 0; i < detailsArr.length; i++) {
        arrangedDetails.push(detailsArr[i]);
    }

    return arrangedDetails;
}

/**
 * 
 * @param {array} processDetails 
 * @returns array
 */
export const areReformulatedProposals = (processDetails) => processDetails.length > 1 && processDetails[0].is_reformulated;
export const areBundleProposals = (processDetails) => processDetails.length > 1 && processDetails[0].is_bundle;

export const getReformulatedError = (errorTag) => {
    switch (errorTag) {
        case REFORMULATED_ERROR_TAGS.INVALID_CONTRACT_INFORMATION:
            return 'processDetails.error.reformulatedInvalidInformation';
        case REFORMULATED_ERROR_TAGS.INVALID_CONTRACT:
            return 'processDetails.error.reformulatedInvalidContract';
        case REFORMULATED_ERROR_TAGS.DUPLICATE_CONTRACT_ID:
            return 'processDetails.error.reformulatedDuplicateContract';
        case REFORMULATED_ERROR_TAGS.DUPLICATE_CONTRACT_ID_NEW:
            return 'processDetails.error.reformulatedExistingContract';
        default:
            return 'processDetails.error.default';
    }
}

/**
 * Method to edit payload to send when sending filters to API
 * @param {object} data 
 * @returns 
 */
export const filtersPayload = (data, options) => {
    const payloadToSend = {
        ...data,
        [INPUT_NAMES.PRODUCT_TYPE_ID]:
            (isFieldDefined(data?.[INPUT_NAMES.PRODUCT_TYPE_ID]) && data?.[INPUT_NAMES.PRODUCT_TYPE_ID].length > 0) ?
                JSON.stringify(data?.[INPUT_NAMES.PRODUCT_TYPE_ID].map(state => state.id)) :
                null,
        [INPUT_NAMES.HAS_BATTERY]:
            isFieldDefined(data?.[INPUT_NAMES.PRODUCT_TYPE_ID]) && data?.[INPUT_NAMES.PRODUCT_TYPE_ID].length > 0 ?
                data?.[INPUT_NAMES.PRODUCT_TYPE_ID].find(product => !!product?.has_battery)?.has_battery :
                null,
        [INPUT_NAMES.SERVICE_MANAGER]:
            (isFieldDefined(data?.[INPUT_NAMES.SERVICE_MANAGER]) && data?.[INPUT_NAMES.SERVICE_MANAGER].length > 0) ?
                JSON.stringify(data?.[INPUT_NAMES.SERVICE_MANAGER]) :
                null,
        [INPUT_NAMES.SERVICE_OPERATION_MANAGERS]:
            (isFieldDefined(data?.[INPUT_NAMES.SERVICE_OPERATION_MANAGERS]) && data?.[INPUT_NAMES.SERVICE_OPERATION_MANAGERS].length > 0) ?
                JSON.stringify(data?.[INPUT_NAMES.SERVICE_OPERATION_MANAGERS].map(serviceOperationManager => serviceOperationManager.id)) :
                null,
        [INPUT_NAMES.BUSINESS_MODEL_IDS]:
            (isFieldDefined(data?.[INPUT_NAMES.PAYMENT_METHODS]) && data?.[INPUT_NAMES.PAYMENT_METHODS].length > 0) ?
                JSON.stringify(data?.[INPUT_NAMES.PAYMENT_METHODS].map(paymentMethod => paymentMethod.business_model_ids).flat()) :
                null,
        [INPUT_NAMES.PAYMENT_METHODS]: undefined, //removed from request
        [INPUT_NAMES.PARTNER_ID]:
            (isFieldDefined(data?.[INPUT_NAMES.PARTNER_ID]) && data?.[INPUT_NAMES.PARTNER_ID].length > 0) ?
                JSON.stringify(data?.[INPUT_NAMES.PARTNER_ID].map(partner => partner.id)) :
                null,
        [INPUT_NAMES.STATE_ID]:
            (isFieldDefined(data?.[INPUT_NAMES.STATE_ID]) && data?.[INPUT_NAMES.STATE_ID].length > 0) ?
                JSON.stringify(data?.[INPUT_NAMES.STATE_ID].map(state => state.id)) :
                null,
        [INPUT_NAMES.REASON_IDS]:
            (isFieldDefined(data?.[INPUT_NAMES.REASON_IDS]) && data?.[INPUT_NAMES.REASON_IDS].length > 0) ?
                JSON.stringify(data?.[INPUT_NAMES.REASON_IDS].map(state => state.id)) :
                null,
    }

    return payloadToSend;
}

export const STATES_WITH_PRE_SURVEY = [
    PROCESS_STATE_IDS.ADJUDICATED,
    PROCESS_STATE_IDS.SITE_SURVEY_REQUESTED,
    PROCESS_STATE_IDS.SITE_SURVEY_SCHEDULED,
    PROCESS_STATE_IDS.SITE_SURVEY_EXECUTED,
    // PROCESS_STATE_IDS.PARTNER_PRICING_COMPLETED,
]

export const hasSurveyValueChanged = (hasSurveyChanges, state, preValue, posValue) => (
    hasSurveyChanges && !STATES_WITH_PRE_SURVEY.includes(state) && !checkEquality(preValue, posValue)
)

/**
 * arrangeStructuresView -> Method to organize the way the user see the structures
 * @param {array} structures 
 * @returns 
 */
export const arrangeStructuresView = (structures) => structures?.map((structure, index) => {
    if(isDefined(structure) && StructuresNamesArray.includes(structure)) {
        return (
            <span key={`${index}_${structure}`}>
                <IntlMessages id={`label.${structure}`} />
                {structures.length - 1 === index ? ' ' : ', '}
            </span>
        )
    }
    if(isDefined(structure) && !StructuresNamesArray.includes(structure)) {
        return (
            <span key={`${index}_${structure}`}>
                {structure}
                {structures.length - 1 === index ? ' ' : ', '}
            </span>
        )
    }
    return null
})

export const arrangeStructuresViewObject = (structures) => structures?.map((structures, idx) => {
    return (
        structures.map((structure, index) => {
            return (
                <span key={`${index}_${structure}`}>
                    <IntlMessages id={`label.${structure}`} />
                    {structures.length - 1 === index ? ' ' : ', '}
                </span>
            )
        })
    )
})

/**
 * Method to get a sum of all costs of an array
 * @param {array} costs 
 * @returns 
 */
export const costsSum = (costs) => costs.reduce((sumCost, cost) => (parseFloat(cost) || 0) + sumCost, 0);

export const tirErrorLabel = (message) => {
    switch (message) {
        case TIR_ERRORS.NOMINAL_POWER_TRESHOLD:
            return 'processDetails.tir.nominalPowerTresholdLabel';
        case TIR_ERRORS.CUSTOMER_RISK:
            return 'processDetails.tir.customerRiskLabel';
        case TIR_ERRORS.MISSING_TARIFF:
            return 'processDetails.tir.missingTariffLabel';
        case TIR_ERRORS.MISSING_LOCAL_ENERGY_COMMUNITY_OBJ:
            return 'processDetails.tir.missingLocalEnergyCommunityObj';
        case TIR_ERRORS.GENERAL_ERROR:
        case TIR_ERRORS.SALES_ERROR:
        default:
            return 'processDetails.tir.generalErrorLabel';
    }
}

export const conditionsToSendValidProductionAssumptionsNull = (fieldDataSS, newFieldDataSS, payload) => {
    if(
        (fieldDataSS?.[SITE_SURVEY_NAMES.SHOW_VALID_PRODUCTION_WARNING]) || 
        (!isFieldDefined(payload?.[SITE_SURVEY_NAMES.VALID_PRODUCTION_ASSUMPTIONS])) ||
        (fieldDataSS?.[SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH] !== newFieldDataSS?.[SITE_SURVEY_NAMES.ANNUAL_PRODUCTION_MWH])
    ) {
        return true
    } else 
        return false
}
